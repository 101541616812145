import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { MdEdit, MdDeleteForever } from 'react-icons/md';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import * as S from './styles';

const columns = [
  { id: 'name', label: 'Nome', minWidth: 170 },
  { id: 'enabled', label: 'Ativo?', minWidth: 170 },
  { id: 'court', label: 'Quadra', minWidth: 270 },
];

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
});

export default function Camera({ cameras, onDelete }) {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map(column => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
              <TableCell key="ações">Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {cameras
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map(row => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                    <TableCell key={row.id}>{row.name}</TableCell>
                    <TableCell key={row.id}>{row.enabled ? 'Sim' : 'Não'}</TableCell>
                    <TableCell key={row.id}>{row?._embedded?.court?.name}</TableCell>
                    <TableCell>
                      <S.Actions>
                        <Link to={`/cameras/edit/${row.id}`}>
                          <button type="button" className="editar">
                            <MdEdit color="#fff" size={20} />
                            Editar
                          </button>
                        </Link>

                        <button
                          type="button"
                          className="cancelar"
                          onClick={() => onDelete(row.id)}
                        >
                          <MdDeleteForever color="#fff" size={20} />
                          Deletar
                        </button>
                      </S.Actions>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={cameras.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
}

Camera.propTypes = {
  cameras: PropTypes.arrayOf(PropTypes.string).isRequired,
  onDelete: PropTypes.func.isRequired,
};
