import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { signOut } from '../../store/modules/auth/actions';
import * as S from './styles';
import logo from '../../assets/logo.svg';

export default function Header() {
  const dispatch = useDispatch();

  const profile = useSelector(state => state.user.profile);

  function handleSignOut() {
    dispatch(signOut());
  }

  return (
    <S.Container>
      <S.Content>
        <nav>
          <img src={logo} alt="Imagem do perfil" width="60" />
          <Link to="/dashboard">Arena</Link>
          <Link to="/quadra">Quadras</Link>
          <Link to="/camera">Cameras</Link>
        </nav>

        <aside>
          <S.Profile>
            <div>
              <strong>{profile}</strong>
              <Link to="/">Meu perfil</Link>
            </div>

            <img src={logo} alt="Imagem do perfil" />
          </S.Profile>

          <div>
            <button type="button" onClick={handleSignOut}>
              Sair
            </button>
          </div>
        </aside>
      </S.Content>
    </S.Container>
  );
}
