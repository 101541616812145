import React from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';

import { Container } from './styles';
import api from '../../services/api';
import ArenaForm from '../../components/ArenaForm';

export default function Arena({ history }) {
  const location = useLocation();
  const client = location.state;

  async function handleSubmit(value) {
    try {
      await api.post(`/arenas`, { ...value });

      history.push('/dashboard');
    } catch (error) {
      console.tron.log(error);
      toast.error(`Error na criação do arena`);
    }
  }
  return (
    <Container>
      <ArenaForm onSubmit={handleSubmit} client={client} />
    </Container>
  );
}

Arena.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};
