import React, { useState, useEffect, useCallback } from 'react';
import { MdAddCircleOutline } from 'react-icons/md';
import { Link } from 'react-router-dom';

import Swal from 'sweetalert2';
import api from '../../services/api';
import * as S from './styles';
import Courts from '../../components/Courts';

export default function Court() {
  const [courts, setCourts] = useState([]);

  const initCourts = useCallback(async () => {
    const response = await api.get(`courts`);
    const { courts: courtsResponse } = response.data._embedded;

    setCourts(courtsResponse);
  }, []);

  useEffect(() => {
    initCourts();
  }, [initCourts]);

  async function handleDelete(id) {
    Swal.fire({
      title: 'Tem certeza que deseja deletar essa quadra?',
      text: 'Você não poderá reverter isso!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Sim, delete!',
    }).then(async result => {
      if (result.isConfirmed) {
        await api.delete(`/courts/${id}`);
        initCourts();
        Swal.fire({
          title: 'Deletado!',
          text: 'A quadra foi deletada.',
          icon: 'success',
        });
      }
    });
  }

  return (
    <S.Container>
      <header>
        <strong>Quadras</strong>
        <Link to="/quadras/create">
          <button type="button">
            <MdAddCircleOutline color="#fff" size={20} />
            Adicionar Quadra
          </button>
        </Link>
      </header>
      <br />
      <br />
      <Courts courts={courts} onDelete={handleDelete} />
    </S.Container>
  );
}
