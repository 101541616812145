/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import { Form, Input } from '@rocketseat/unform';
import PropTypes from 'prop-types';

function CameraForm({ onSubmit, camera, courts }) {
  const [selectedCourt, setSelectedCourt] = useState('');

  useEffect(() => {
    setSelectedCourt(camera?._embedded?.court.id);
  }, [camera]);

  const handleSelectCourt = selectedValue => {
    setSelectedCourt(selectedValue);
  };

  async function handleSubmit(value) {
    await onSubmit({ ...value, court: `/${selectedCourt}` });
  }

  return (
    <Form initialData={camera} onSubmit={handleSubmit}>
      <label htmlFor="name">Nome</label>
      <Input name="name" id="name" type="text" required placeholder='Nome da Câmera'/>

      <label htmlFor="court">Quadra</label>
      <select id="court" onChange={e => handleSelectCourt(e.target.value)}>
        <option value="">Selecione uma quadra</option>
        {courts.map(court => (
          <option
            key={court}
            value={court.id}
            selected={court.id === selectedCourt ? 'selected' : ''}
          >
            {court.name}
          </option>
        ))}
      </select>
      <button type="submit">Salvar</button>
    </Form>
  );
}

export default CameraForm;

CameraForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  courts: PropTypes.shape({
    map: PropTypes.func.isRequired,
  }),
};
CameraForm.defaultProps = {
  courts: {
    map: () => {},
  },
};
