/* eslint-disable jsx-a11y/label-has-associated-control */
import { Form, Input } from '@rocketseat/unform';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import api from '../../services/api';
import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

function CourtForm({ onSubmit, court, arenas }) {
  const [selectedArena, setSelectedArena] = useState('');
  const [qrCode, setQrCode] = useState('');
  const [cameras, setCameras] = useState('');
  const canvasRef = useRef(null);

  useEffect(() => {
    setSelectedArena(court?.arena?.id);
  }, [court]);

  const handleSelectArena = selectedValue => {
    setSelectedArena(selectedValue);
  };

  async function handleSubmit(value) {
    await onSubmit({ ...value, arena: `/${selectedArena}` });
  }

  useEffect(() => {
    async function loadQrCodeCourt() {
      const {
        data: { qrcode },
      } = await api.get(`qrcodes?court_id=${court?.id}`);

      setQrCode(qrcode);
    }

    loadQrCodeCourt();
  }, [court]);

  useEffect(() => {
    async function loadCameraByCourt() {
      const {
        data: {
          _embedded: { cameras },
        },
      } = await api.get(`courts/${court?.id}/cameras`);
      setCameras(cameras);
    }

    loadCameraByCourt();
  }, [court]);

  const imprimirQRCode = () => {
    const janelaImpressao = window.open('', '_blank');

    janelaImpressao.document.write(
      '<html><head><title>Imprimir QR Code</title></head><body>'
    );
    janelaImpressao.document.write(
      '<img src="data:image/png;base64,' +
        qrCode +
        '" alt="Imagem QR Code" width="100%" height="100%">'
    );
    janelaImpressao.document.write('</body></html>');

    janelaImpressao.document.close();
    janelaImpressao.print();
  };

  return (
    <>
      <Form initialData={court} onSubmit={handleSubmit}>
        <label htmlFor="name">Nome</label>
        <Input
          name="name"
          id="name"
          type="text"
          placeholder="Nome da quadra"
          required
        />

        <label htmlFor="arena">Arena</label>
        <select
          id="arena"
          onChange={e => handleSelectArena(e.target.value)}
          placeholder="Escolha sua arena"
        >
          <option value="">Selecione uma arena</option>
          {arenas.map(arena => (
            <option
              key={arena}
              value={arena.id}
              selected={arena.id === selectedArena ? 'selected' : ''}
            >
              {arena.name}
            </option>
          ))}
        </select>

        <button type="submit">Salvar</button>
      </Form>

      <div
        style={{
          display: 'flex',
          flex: 1,
          flexDirection: 'row',
          justifyContent: 'space-around',
        }}
      >
        {qrCode && (
          <div>
            <h3>Qr code da quadra</h3>
            <img
              ref={canvasRef}
              src={`data:image/png;base64,${qrCode}`}
              id="qrcode"
              alt="Qrcode da quadra"
            />
            <button onClick={imprimirQRCode}>Imprimir QR Code</button>
          </div>
        )}

        <div>
          <h3>Câmeras da quadra</h3>

          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Nome</TableCell>
                  <TableCell>RTMP Address</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {cameras && cameras.map(row => (
                  <TableRow key={row.id}>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row.rtmp_address}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

        </div>
      </div>
    </>
  );
}

export default CourtForm;

CourtForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  arenas: PropTypes.shape({
    map: PropTypes.func.isRequired,
  }),
  court: PropTypes.shape({
    name: PropTypes.string,
    arena: PropTypes.string,
  }).isRequired,
};

CourtForm.defaultProps = {
  arenas: {
    map: () => {},
  },
};
