import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import { Container } from './styles';
import api from '../../services/api';
import CameraForm from '../../components/CameraForm';

export default function CameraCreate({ history }) {
  const [courts, setCourts] = useState([]);

  const initCourts = useCallback(async () => {
    const response = await api.get(`courts`);
    const { courts: courtsResponse } = response.data._embedded;

    setCourts(courtsResponse);
  }, []);

  useEffect(() => {
    initCourts();
  }, [initCourts]);

  async function handleSubmit(value) {
    try {
      await api.post(`/cameras`, { ...value });

      history.push('/camera');
    } catch (error) {
      toast.error(`Essa quadra já possui duas câmeras`);
    }
  }
  return (
    <Container>
      <CameraForm courts={courts} onSubmit={handleSubmit} />
    </Container>
  );
}

CameraCreate.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};
