import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import { Container } from './styles';
import api from '../../services/api';
import CourtForm from '../../components/CourtForm';

export default function CourtEdit({ history, match }) {
  const { id } = match.params;

  const [arenas, setArenas] = useState([]);

  const initArenas = useCallback(async () => {
    const response = await api.get(`arenas`);
    const { arenas: arenasResponse } = response.data._embedded;
    setArenas(arenasResponse);
  }, []);

  useEffect(() => {
    initArenas();
  }, [initArenas]);

  const [court, setCourt] = useState({});

  useEffect(() => {
    async function loadCourt() {
      const response = await api.get(`courts/${id}?projection=courtArena`);
      setCourt(response.data);
    }

    loadCourt();
  }, [id]);

  async function handleSubmit(value) {
    try {
      await api.patch(`/courts/${id}`, { ...value });

      history.push('/quadra');
    } catch (error) {
      console.tron.log(error);
      toast.error(`Error na edição da quadra`);
    }
  }
  return (
    <Container>
      <CourtForm onSubmit={handleSubmit} court={court} arenas={arenas} />
    </Container>
  );
}

CourtEdit.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.object.isRequired,
  }).isRequired,
};
