import styled from 'styled-components';

export const Container = styled.div`
  form {
    input {
      background: white !important;
      ::placeholder {
        color: #888;
      }
    }
  }
`;
