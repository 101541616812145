import { all, takeLatest, call, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import Types from './types';

import apiGateway from '../../../services/apiGateway';
import api from '../../../services/api';
import history from '../../../services/history';

import { signFailure, signInSuccess } from './actions';

export function setToken(token) {
  if (!token) return;

  if (token) {
    api.defaults.headers.Authorization = `Basic ${token}`;
  }
}

export function setTokenByPayload({ payload }) {
  if (!payload) return;

  const { token } = payload.auth;

  if (token) {
    api.defaults.headers.Authorization = `Basic ${token}`;
  }
}

export function* signIn({ payload }) {
  try {
    const { usuario, password } = payload;
    const token = Buffer.from(`${usuario}:${password}`, 'utf-8').toString(
      'base64'
    );
    apiGateway.defaults.headers.Authorization = `Basic ${token}`;

    const response = yield call(apiGateway.post, 'auth/me');
    const { name } = response.data;

    yield put(signInSuccess(name, token));
    setToken(token);

    history.push('/dashboard');
  } catch (error) {
    toast.error('Falha na autenticação, verifique seus dados');

    yield put(signFailure());
  }
}

export function* signUp({ payload }) {
  try {
    const { name, email, password } = payload;

    yield call(apiGateway.post, '/users', {
      name,
      email,
      password,
    });

    history.push('/');
  } catch (error) {
    toast.error('Falha no cadastro, verifique seus dados');
    yield put(signFailure());
  }
}

export function signOut() {
  history.push('/');
}

export default all([
  takeLatest(Types.SIGN_IN_REQUEST, signIn),
  takeLatest(Types.SIGN_UP_REQUEST, signUp),
  takeLatest('persist/REHYDRATE', setTokenByPayload),
  takeLatest(Types.SIGN_OUT, signOut),
]);
