/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { Form, Input } from '@rocketseat/unform';
import PropTypes from 'prop-types';

function ArenaForm({ onSubmit, arena }) {
  async function handleSubmit(value) {
    await onSubmit(value);
  }

  return (
    <Form initialData={arena} onSubmit={handleSubmit}>
      <label htmlFor="name">Nome</label>
      <Input
        name="name"
        placeholder="Nome da arena"
        id="name"
        type="text"
        required
      />

      <label htmlFor="address">Endereço</label>
      <Input
        name="address"
        id="address"
        required
        placeholder="Endereço da arena"
      />

      <label htmlFor="city">Cidade</label>
      <Input name="city" id="city" required placeholder="Cidade da arena" />

      <label htmlFor="state">Estado</label>
      <Input name="state" id="state" required placeholder="Estado da arena" />

      <label htmlFor="cep">Cep:</label>
      <Input name="cep" id="cep" required placeholder="Cep da arena" />

      <button type="submit">Salvar</button>
    </Form>
  );
}

export default ArenaForm;

ArenaForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};
