import React, { useState, useEffect, useCallback } from 'react';
import { MdAddCircleOutline } from 'react-icons/md';
import { Link } from 'react-router-dom';

import Swal from 'sweetalert2';
import api from '../../services/api';
import * as S from './styles';
import Camera from '../../components/Camera';

export default function Cameras() {
  const [cameras, setCameras] = useState([]);

  const initCameras = useCallback(async () => {
    const response = await api.get(`cameras`);
    const { cameras: camerasResponse } = response.data._embedded;

    setCameras(camerasResponse);
  }, []);

  useEffect(() => {
    initCameras();
  }, [initCameras]);

  async function handleDelete(id) {
    Swal.fire({
      title: 'Tem certeza que deseja deletar essa câmera?',
      text: 'Você não poderá reverter isso!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Sim, delete!',
    }).then(async result => {
      if (result.isConfirmed) {
        await api.delete(`/cameras/${id}`);
        initCameras();
        Swal.fire({
          title: 'Deletado!',
          text: 'A câmera foi deletada.',
          icon: 'success',
        });
      }
    });
  }

  return (
    <S.Container>
      <header>
        <strong>Cameras</strong>
        <Link to="/cameras/create">
          <button type="button">
            <MdAddCircleOutline color="#fff" size={20} />
            Adicionar Câmera
          </button>
        </Link>
      </header>
      <br />
      <br />
      <Camera cameras={cameras} onDelete={handleDelete} />
    </S.Container>
  );
}
