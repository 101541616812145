import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import { Container } from './styles';
import api from '../../services/api';
import ArenaForm from '../../components/ArenaForm';

export default function ArenaEdit({ history, match }) {
  const { id } = match.params;

  const [arena, setArena] = useState({});

  useEffect(() => {
    async function loadArena() {
      const response = await api.get(`arenas/${id}`);
      setArena(response.data);
    }

    loadArena();
  }, [id]);

  async function handleSubmit(value) {
    try {
      await api.put(`/arenas/${id}`, { ...value });

      history.push('/dashboard');
    } catch (error) {
      console.tron.log(error);
      toast.error(`Error na edição da arena`);
    }
  }
  return (
    <Container>
      <ArenaForm onSubmit={handleSubmit} arena={arena} />
    </Container>
  );
}

ArenaEdit.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};
