import React, { useState, useEffect, useCallback } from 'react';
import { MdAddCircleOutline } from 'react-icons/md';
import { Link } from 'react-router-dom';

import Swal from 'sweetalert2';
import api from '../../services/api';
import * as S from './styles';
import Arena from '../../components/Arena';

export default function Dashboard() {
  const [arenas, setArenas] = useState([]);

  const initArenas = useCallback(async () => {
    const response = await api.get(`arenas`);
    const { arenas: arenasResponse } = response.data._embedded;
    setArenas(arenasResponse);
  }, []);

  useEffect(() => {
    initArenas();
  }, [initArenas]);

  async function handleDelete(id) {
    Swal.fire({
      title: 'Tem certeza que deseja deletar essa arena?',
      text: 'Você não poderá reverter isso!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Sim, delete!',
    }).then(async result => {
      if (result.isConfirmed) {
        await api.delete(`/arenas/${id}`);
        initArenas();
        Swal.fire({
          title: 'Deletado!',
          text: 'A arena foi deletada.',
          icon: 'success',
        });
      }
    });
  }

  return (
    <S.Container>
      <header>
        <strong>Arenas</strong>
        <Link to="/arenas/create">
          <button type="button">
            <MdAddCircleOutline color="#fff" size={20} />
            <p>Adicionar arena</p>
          </button>
        </Link>
      </header>
      <br />
      <br />
      <Arena arenas={arenas} onDelete={handleDelete} />
    </S.Container>
  );
}
