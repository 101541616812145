import React, { useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import { Container } from './styles';
import api from '../../services/api';
import CourtForm from '../../components/CourtForm';

export default function CourtsCreate({ history }) {
  const [arenas, setArenas] = useState([]);

  const initArenas = useCallback(async () => {
    const response = await api.get(`arenas`);
    const { arenas: arenasResponse } = response.data._embedded;
    setArenas(arenasResponse);
  }, []);

  useEffect(() => {
    initArenas();
  }, [initArenas]);

  async function handleSubmit(value) {
    try {
      await api.post(`/courts`, { ...value });

      history.push('/quadra');
    } catch (error) {
      console.tron.log(error);
      toast.error(`Error na criação da quadra`);
    }
  }
  return (
    <Container>
      <CourtForm arenas={arenas} onSubmit={handleSubmit} />
    </Container>
  );
}

CourtsCreate.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};
